(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientFollowUpController', PatientFollowUpController);

    PatientFollowUpController.$inject = ['$scope', '$rootScope', '$sce', '$state', '$stateParams', '$timeout', '$interval', '$filter', '$translate', '$uibModal', '$window', '$location', '$anchorScroll', 'entity', 'Message', 'moment', 'Principal', 'MedicationVariant', 'Media', 'BiologyResult', 'Prescription', 'Inbox', 'Visit', 'PatientTherapy'];

    function PatientFollowUpController($scope, $rootScope, $sce, $state, $stateParams, $timeout, $interval, $filter, $translate, $uibModal, $window, $location, $anchorScroll, entity, Message, moment, Principal, MedicationVariant, Media, BiologyResult, Prescription, Inbox, Visit, PatientTherapy) {
        const vm = this;

        vm.patient = entity;

        vm.openPatientHistory = openPatientHistory;
        vm.openVisitDetail = openVisitDetail;
        vm.closeAwayMessage = closeAwayMessage;

        $translate('continuumplatformApp.patient.follow-up.title', {initials: entity.initials}).then(title => {
            $window.document.title = title;
        });

        Principal.identity().then(function (account) {
            vm.isAdmin = account.authorities.includes('ROLE_ADMIN');
        });

        vm.sendMessageBody = null;
        vm.messages = [];
        vm.lastMessageDate = '1970-01-01T00:00:00.000Z';
        vm.isSendingMessage = false;
        vm.isLoadingMessages = false;
        vm.isFirstLoadingMessages = true;
        vm.messageImportant = null;
        vm.medications = null;
        vm.sendMessage = sendMessage;
        vm.markAllRead = markAllRead;

        loadMessages().then(() => {
            subscribeDivMessageListScrollHeight();
        });

        loadVisits();
        loadPatientTherapy();
        // TODO global message loading
        let markAllReadPromise = null;
        let stopLoad = $interval(loadMessages, 60000);

        function sendMessage() {
            vm.isSendingMessage = true;

            Message.send({
                important: vm.messageImportant,
                body: vm.sendMessageBody,
                patientId: vm.patient.id
            }, function () {
                vm.isSendingMessage = false;
                vm.sendMessageBody = null;
                vm.messageImportant = null;
                loadMessages();
                $timeout(function () {
                    angular.element('.messaging textarea').focus();
                });
            }, function () {
                vm.isSendingMessage = false;
                $timeout(function () {
                    angular.element('.messaging textarea').focus();
                });
            });
        }

        const unsubscribe1 = $rootScope.$on('continuumplatformApp:patientTherapyUpdate', function () {
            loadMessages();
        });
        const unsubscribe2 = $rootScope.$on('continuumplatformApp:patientTreatmentUpdate', function () {
            loadMessages();
        });
        const unsubscribe3 = $rootScope.$on('continuumplatformApp:patientTreatmentPhaseUpdate', function () {
            loadMessages();
        });
        const unsubscribe4 = $rootScope.$on('continuumplatformApp:biologyResultUpdate', function () {
            loadMessages();
        });
        const unsubscribe5 = $rootScope.$on('continuumplatformApp:prescriptionUpdate', function () {
            loadMessages();
        });
        const unsubscribe6 = $rootScope.$on('continuumplatformApp:mediaUpdate', function () {
            loadMessages();
        });

        $scope.$on('$destroy', function (event) {
            unsubscribe1(event);
            unsubscribe2(event);
            unsubscribe3(event);
            unsubscribe4(event);
            unsubscribe5(event);
            unsubscribe6(event);
            vm.unsubscribeMessageListScrollHeight();
            $interval.cancel(stopLoad);
            if (markAllReadPromise)
                $timeout.cancel(markAllReadPromise);
        });

        function loadMessages() {
            if (vm.isLoadingMessages) {
                return Promise.resolve();
            }

            vm.isLoadingMessages = true;

            return Message.query({
                "patientId.equals": vm.patient.id,
                "date.greaterThan": vm.lastMessageDate,
                "sort": ["date,asc"],
            }).$promise.then((data) => {
                vm.isLoadingMessages = false;
                if (data.length) {
                    vm.lastMessageDate = data[data.length - 1].date;
                    data.forEach((d) => {
                        vm.messages.push(d);
                    });
                }
                if (vm.isFirstLoadingMessages) {
                    vm.isFirstLoadingMessages = false;
                    markAllReadPromise = $timeout(function () {
                        markAllRead();
                    }, 3000);
                }
            });
        }

        /**
         * Subscribe the change of the scroll height of the div listMessage.
         */
        function subscribeDivMessageListScrollHeight() {
            if (!vm.unsubscribeMessageListScrollHeight) {
                vm.divMessageList = document.getElementById('listMessage');

                vm.unsubscribeMessageListScrollHeight = $scope.$watch("vm.divMessageList.scrollHeight", function (newVal, oldVal) {
                    scrollEndMessages();
                });
            }
        }

        function markAllRead() {
            Inbox.readAll(vm.patient.id).then(function (response) {
                // TODO improve with event listener
                $rootScope.unreadMessages -= response.data;
                $rootScope.$emit('continuumplatformApp:messageRead', response.data);
            });
        }

        function scrollEndMessages() {
            const elem= document.getElementById('listMessage');
            elem.scrollTop = elem.scrollHeight;
        }

        function loadVisits() {
            Visit.query({
                "patientId.equals": vm.patient.id,
                "status.equals": 'DONE',
                "sort": "date,desc",
                "size": 1000
            }, function (visits) {
                vm.visits = visits;
                if (vm.visits) {
                    vm.visits.forEach((v) => {
                        vm.hasVisitWithQolEq5d5l = vm.hasVisitWithQolEq5d5l || (v.steps && v.steps.includes('QOL_EQ_5D_5L'));
                        vm.hasVisitWithQolQlqC30 = vm.hasVisitWithQolQlqC30 || (v.steps && v.steps.includes('QOL_QLQ_C30'));
                        vm.hasVisitWithQolPicto = vm.hasVisitWithQolPicto || (v.steps && v.steps.includes('PHARMA_ITW_QOL_PICTO'));
                    });
                }
            });
        }

        function loadPatientTherapy() {
            vm.isLoadingPatientTherapy = true;
            if (vm.patient.patientTherapyId) {
                PatientTherapy.get({'id': vm.patient.patientTherapyId}).$promise.then(patientTherapy => {
                    vm.patientTherapy = patientTherapy;
                    vm.medications = patientTherapy.medications;
                    vm.isLoadingPatientTherapy = false;
                });
            } else {
                vm.medications = [];
                vm.isLoadingPatientTherapy = false;
            }
        }

        function closeAwayMessage() {
            vm.careTeamMemberAway = [];
        }

        function openPatientHistory(id) {
            $state.go('patient-detail.alert', {id});
        }

        function openVisitDetail(id) {
            $state.go('visit-detail', {id});
        }

        (function ($, viewport) {
            $(document).ready(function () {
                vm.isViewportXs = viewport.is('xs');

                $(window).resize(
                    viewport.changed(function() {
                        vm.isViewportXs = viewport.is('xs');
                    })
                );
            });
        })(jQuery, ResponsiveBootstrapToolkit);


    }
})();
