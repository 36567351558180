(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('PatientTherapy', PatientTherapy);

    PatientTherapy.$inject = ['$http', '$resource', 'DateUtils'];

    function PatientTherapy($http, $resource, DateUtils) {
        const resourceUrl = 'api/patient-therapies/:id';

        const service = $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'queryView': {url: 'api/patient-therapy-view', method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        // compute age
                        if (data.patient && data.patient.birthDate)
                            data.patient.age = moment().diff(data.patient.birthDate, 'years');

                        data.startDate = DateUtils.convertLocalDateFromServer(data.startDate);
                        data.day1Date = DateUtils.convertLocalDateFromServer(data.day1Date);
                        data.endDate = DateUtils.convertLocalDateFromServer(data.endDate);
                        data.stopDate = DateUtils.convertLocalDateFromServer(data.stopDate);
                    }
                    return data;
                }
            },
            'getTherapyContractLines': {
                url: 'api/patient-therapies/:id/therapy-contract-lines',
                method: 'GET',
                isArray: true,
                params: {
                    date: null
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getSideEffects': {
                url: 'api/patient-therapies/:id/side-effects',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'queryDistinctFacilities': {url: 'api/patient-therapies/distinct-facilities', method: 'GET', isArray: true},
            'queryDistinctTherapies': {url: 'api/patient-therapies/distinct-therapies', method: 'GET', isArray: true},
            'queryDistinctDiseases': {url: 'api/patient-therapies/distinct-diseases', method: 'GET', isArray: true},
            'queryDistinctPrescribers': {
                url: 'api/patient-therapies/distinct-prescribers',
                method: 'GET',
                isArray: true
            },
            'queryDistinctAssignees': {
                url: 'api/patient-therapies/distinct-assignees',
                method: 'GET',
                isArray: true
            },
            'queryDistinctPrograms': {url: 'api/patient-therapies/distinct-programs', method: 'GET', isArray: true},
            'start': {
                url: 'api/patient-therapies/:id/start',
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.day1Date = DateUtils.convertLocalDateToServer(copy.day1Date);
                    return angular.toJson(copy);
                }
            },
            'startPreview': {
                url: 'api/patient-therapies/:id/start-preview',
                method: 'POST',
                isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.day1Date = DateUtils.convertLocalDateToServer(copy.day1Date);
                    return angular.toJson(copy);
                }
            },
            'reassign': {
                url: 'api/patient-therapies/:id/reassign',
                method: 'POST'
            },
            'shift': {
                url: 'api/patient-therapies/:id/shift',
                method: 'POST'
            },
            'pause': {
                url: 'api/patient-therapies/:id/pause',
                method: 'POST'
            },
            'resume': {
                url: 'api/patient-therapies/:id/resume',
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.resumeDate = DateUtils.convertLocalDateToServer(copy.resumeDate);
                    return angular.toJson(copy);
                }
            },
            'stop': {
                url: 'api/patient-therapies/:id/stop',
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.stopDate = DateUtils.convertLocalDateToServer(copy.stopDate);
                    return angular.toJson(copy);
                }
            },
            'cancel': {
                url: 'api/patient-therapies/:id/cancel',
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.stopDate = DateUtils.convertLocalDateToServer(copy.stopDate);
                    return angular.toJson(copy);
                }
            },
            'renew': {
                url: 'api/patient-therapies/:id/renew',
                method: 'POST',
                transformRequest: function (data) {
                    const copy = angular.copy(data);
                    copy.stopDate = DateUtils.convertLocalDateToServer(copy.stopDate);
                    return angular.toJson(copy);
                }
            },
            'nextPatientTherapy': {
                url: 'api/patient-therapies/:id/nextPatientTherapy',
                method: 'POST',
                transformRequest: function (data) {
                    const copy = angular.copy(data);
                    return angular.toJson(copy);
                }
            },
            'satisfaction': {
                url: 'api/patient-therapies/:id/satisfaction',
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                }
            },
            'addMedication': {
                url: 'api/patient-therapies/:id/medications',
                method: 'POST'
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.day1Date = DateUtils.convertLocalDateToServer(copy.day1Date);
                    copy.stopDate = DateUtils.convertLocalDateToServer(copy.stopDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    if (copy.patientIdentityUpdate && copy.patientIdentityUpdate.birthDate) {
                        copy.patientIdentityUpdate.birthDate = DateUtils.convertLocalDateToServer(copy.patientIdentityUpdate.birthDate);
                    }
                    copy.day1Date = DateUtils.convertLocalDateToServer(copy.day1Date);
                    return angular.toJson(copy);
                }
            },
            'nextContractLines': {
                url: 'api/patient-therapies/:id/next-contract-lines',
                method: 'GET',
                isArray: true
            },
            'addMeasureType': {
                url: 'api/patient-therapies/:id/measureTypes/:type',
                method: 'POST',
            },
            'removeMeasureType': {
                url: 'api/patient-therapies/:id/measureTypes/:type',
                method: 'DELETE',
            }
        });

        service.exportCsv = () =>
            $http.get('api/patient-therapies', {
                headers: {Accept: 'text/csv'},
            });

        return service;
    }
})();
