(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MyFollowUpController', MyFollowUpController);

    MyFollowUpController.$inject = ['previousState', 'Principal', 'Visit', 'PatientTherapy'];

    function MyFollowUpController(previousState, Principal, Visit, PatientTherapy) {
        const vm = this;

        vm.previousState = previousState;

        Principal.identity().then(account => {
            vm.patient = account.patient;
            loadVisits(vm.patient.id);
            pain();
        });

        function pain() {
            PatientTherapy.query({'patientId.equals': vm.patient.id}).then(patientTherapies => {
                vm.painEnabled = patientTherapies.some(patientTherapy => {
                    patientTherapy.measureTypes.includes('PAIN');
                });
            });
        }

        function loadVisits(patientId) {
            Visit.query({
                "patientId.equals": patientId,
                "status.equals": 'DONE',
                "sort": "date,desc",
                "size": 1000
            }, function (visits) {
                vm.visits = visits;
            });
        }
    }
})();
