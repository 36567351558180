(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('VisitDetailController', VisitDetailController);

    VisitDetailController.$inject = ['$scope', '$rootScope', '$sce', '$state', '$stateParams', '$interval', '$filter', '$translate', '$uibModal', '$window', 'entity', 'previousState', 'Lightbox', 'Patient', 'PatientTherapy', 'Principal', 'Visit', 'Alert', 'Media', 'PharmAnalysis', 'AdherenceReport', 'SideEffectReport', 'Measure', 'MessageTo', 'Inbox', 'BiologyResult', 'DownloadFile', 'Practitioner'];

    function VisitDetailController($scope, $rootScope, $sce, $state, $stateParams, $interval, $filter, $translate, $uibModal, $window, entity, previousState, Lightbox, Patient, PatientTherapy, Principal, Visit, Alert, Media, PharmAnalysis, AdherenceReport, SideEffectReport, Measure, MessageTo, Inbox, BiologyResult, DownloadFile, Practitioner) {
        const vm = this;

        vm.plan = plan;
        vm.doConfirm = doConfirm;
        vm.undoConfirm = undoConfirm;
        vm.ack = ack;
        vm.reply = reply;
        vm.cancel = cancel;
        vm.shiftOrPlan = shiftOrPlan;
        vm.openPractitioner = openPractitioner;
        vm.openPatient = openPatient;
        vm.openImage = openImage;
        vm.previewSideEffectReport = previewSideEffectReport;
        vm.openMeasureChart = openMeasureChart;
        vm.openMedication = openMedication;
        vm.getNumber = (num) => new Array(num);
        vm.exportPdf = exportPdf;
        vm.editName = editName;
        vm.saveName = saveName;
        vm.restoreName = restoreName;
        vm.onNameKeydown = onNameKeydown;

        vm.saveComment = saveComment;
        vm.restoreComment = restoreComment;

        vm.isExportingPdf = false;

        vm.editingName = false;
        vm.savingName = false;

        vm.editingComment = false;
        vm.savingComment = false;

        vm.interval = null;

        vm.$onInit = () => {
            vm.visit = entity;
            vm.savedName = vm.visit.name;
            vm.savedComment = vm.visit.comment;

            // load basic patient data - will be overridden with full data
            vm.patient = vm.visit.patient;

            vm.previousState = previousState;

            if(entity.name) {
                $window.document.title = entity.name + ' #' + entity.id;
            } else {
                $translate('continuumplatformApp.VisitType.' + entity.type).then(title => {
                    $window.document.title = title + ' #' + entity.id;
                });
            }

            loadAccount();

            countMeasuresAndSideEffectReports();

            // si non traité, on recharge toutes les 10 secondes
            if (!vm.visit.ackDate) {
                vm.interval = $interval(() => {
                    Visit.get({id: vm.visit.id}, v => {
                        if (v.status !== vm.visit.status || v.ackDate !== vm.visit.ackDate) {
                            vm.visit = v;
                        }
                        if (vm.visit.ackDate) {
                            $interval.cancel(vm.interval);
                        }
                    });

                }, 10000);
            }

            const unsubscribe = $rootScope.$on('continuumplatformApp:visitUpdate', loadVisitAndNext);
            $scope.$on('$destroy', () => {

                unsubscribe();

                if (vm.interval) {
                    $interval.cancel(vm.interval);
                }

            });

            if (vm.visit.status === 'DONE') {

                Visit.getQuestions({id: vm.visit.id}, result => vm.questions = result.questions);

                Inbox.readVisitReport(vm.visit.id).then(response => {
                    // TODO improve with event listener
                    $rootScope.unreadMessages -= response.data;
                    $rootScope.$emit('continuumplatformApp:messageRead', response.data);

                    MessageTo.query({
                        reportOfVisitId: vm.visit.id,
                        size: 1000
                    }, recipients => vm.recipients = recipients.map(recipient => {
                        recipient.patientFacility = recipient.practitioner && recipient.practitioner.healthFacilityId === vm.patient.healthFacilityId;
                        return recipient;
                    }), () => {
                        // error is ignored
                    });
                });
            } else {
                loadReminderPractitioners();
            }
        };

        function loadAccount() {
            Principal.identity().then(account => {
                vm.account = account;
                vm.isAdmin = account.authorities.includes('ROLE_ADMIN');
                vm.isPatient = account.patient;
                vm.isPractitionerAllowedToReadPatientHealthData = account.practitioner && account.authorities.includes('PATIENT_HEALTH_READ');

                if (vm.visit.status === 'DONE' && vm.visit.steps) {
                    if (vm.isPractitionerAllowedToReadPatientHealthData
                        && (vm.visit.steps.indexOf('PHARMA_ITW_TREATMENT') !== -1)
                        || vm.visit.steps.indexOf('PHARMA_ITW_TREATMENT_PLAN') !== -1
                    ) {
                        loadPharmAnalyses();
                    }

                    if ((vm.isPatient || vm.isPractitionerAllowedToReadPatientHealthData)) {
                        if (vm.visit.steps.indexOf('adherence') !== -1) {
                            loadAdherence();
                        }
                        if (vm.visit.steps.indexOf('biology') !== -1) {
                            loadBiology();
                        }
                    }
                }

                if (account.practitioner) {
                    vm.isPractitioner = true;
                    vm.isNurse = account.practitioner.job === 'J60';
                    vm.isPharmacist = account.practitioner.job === 'J21';

                    Patient.get({id: vm.visit.patientId}, patient => vm.patient = patient);

                    if (vm.visit.patientTherapyId) {
                        PatientTherapy.get({id: vm.visit.patientTherapyId}, patientTherapy => vm.patientTherapy = patientTherapy);
                        loadNext();
                    }

                } else if(vm.isPatient) {
                    vm.patient = account.patient;
                }
            });
        }

        function countMeasuresAndSideEffectReports() {
            Measure.query(
                {
                    view: 'count',
                    "visitId.equals": vm.visit.id
                },
                (data, headers) => vm.measureCount = parseInt(headers('X-Total-Count')),
                () => vm.measureCount = -1);

            SideEffectReport.query(
                {
                    view: 'count',
                    "visitId.equals": vm.visit.id
                }, (data, headers) => vm.sideEffectReportCount = parseInt(headers('X-Total-Count')),
                () => vm.sideEffectReportCount = -1);
        }

        function loadVisitAndNext() {
            Visit.get({id: vm.visit.id})
                .$promise
                .then((v) => {
                    vm.visit = v;
                    loadNext();
                });
        }

        function loadNext() {
            if(!vm.isPractitioner) {
                return;
            }
            vm.nextVisits = null;
            vm.nextVisitsCount = null;
            Visit.query({
                'patientTherapyId.equals': vm.visit.patientTherapyId,
                'orderingDate.greaterThanOrEqual': vm.visit.orderingDate,
                'id.notEquals': vm.visit.id,
                'sort': ['orderingDate'],
                size: 3
            }, (nextVisits, headers) => {
                vm.nextVisits = nextVisits;
                vm.nextVisitsCount = parseInt(headers('X-Total-Count'));
            });
        }

        function plan() {
            $uibModal.open({
                templateUrl: 'app/activity/visit/visit-plan-dialog.html',
                controller: 'VisitPlanDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    visit: () => angular.copy(vm.visit)
                }
            }).result.then(() => {
                // nothing
            }, () => {
                // nothing
            });
        }

        function doConfirm() {
            $uibModal.open({
                templateUrl: 'app/activity/visit/visit-confirm-dialog.html',
                controller: 'VisitConfirmDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    visit: () => angular.copy(vm.visit)
                }
            }).result.then(() => {
                // nothing
            }, () => {
                // nothing
            });
        }

        function undoConfirm() {
            if(confirm('Le RDV va repasser au statut "à confirmer". Êtes-vous sûr ?')) {
                Visit.undoConfirm({id: vm.visit.id}, {}, loadVisitAndNext);
            }
        }

        function openMedication(medication) {
            $uibModal.open({
                templateUrl: 'app/entities/medication/medication-preview-modal.html',
                controller: 'MedicationPreviewModalController',
                controllerAs: 'vm',
                size: vm.patient.id ? 'lg' : 'md',
                resolve: {
                    medication: () => medication,
                    patientId: () => vm.visit.patientId,
                    options: {}
                }
            }).result.then(() => {
                // nothing
            }, () => {
                // nothing
            });
        }

        function ack() {
            Visit.acknowledge({id: vm.visit.id}, {}, () => loadVisitAndNext());
        }

        function reply() {
            $uibModal.open({
                templateUrl: 'app/activity/visit/visit-reply-dialog.html',
                controller: 'VisitReplyDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    visitId: vm.visit.id
                }
            });
        }

        function cancel() {
            $uibModal.open({
                templateUrl: 'app/activity/visit/visit-cancel-dialog.html',
                controller: 'VisitCancelDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    visit: vm.visit
                }
            }).result.then(result => {
                if (result === 'stopTherapy') {
                    $state.go('patient-therapy-detail', {id: vm.visit.patientTherapyId});
                }
            }, () => {
                // nothing
            });
        }

        function shiftOrPlan() {
            $uibModal.open({
                templateUrl: 'app/activity/visit/visit-shift-or-plan-dialog.html',
                controller: 'VisitShiftOrPlanDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    visit: vm.visit
                }
            }).result.then(result => {
                // nothing
            }, () => {
                // nothing
            });
        }

        function loadBiology() {
            BiologyResult.query({
                view: 'media',
                "visitId.equals": vm.visit.id,
                "sort": ["date,desc"],
                size: 1000 // TODO hard-coded size
            }).$promise
                .then(results => {
                    vm.biologyResults = results.map(br => {
                        br.media = br.media.map(m => {
                            m.trustedInlineUrl = $sce.trustAsResourceUrl('/api/media/' + m.id + '/download?disposition=inline');
                            return m;
                        });
                        return br;
                    });
                });
        }

        function openImage(media) {
            Lightbox.openModal([{
                caption: media.text,
                url: media.trustedInlineUrl
            }], 0);
        }

        function loadPharmAnalyses() {
            PharmAnalysis.query({
                "visitId.equals": vm.visit.id
            }).$promise
                .then(results => {
                    vm.pharmAnalysis = results.length ? results[0] : null;
                });
        }

        function loadAdherence() {
            AdherenceReport.query({
                "visitId.equals": vm.visit.id,
                "sort": 'question'
            }, function (results) {
                vm.adherence = results;
                if (results.length === 6)
                    vm.adherenceScore = results
                        .map(v => v.score)
                        .reduce((a, b) => a + b);
            });
        }

        function openPractitioner(practitioner) {

            Principal.hasAuthority('ROLE_ADMIN').then(function (hasAuthority) {
                if (hasAuthority) {
                    const url = $state.href('practitioner-detail', {id: practitioner.id});
                    $window.open(url, 'practitioner-detail-' + practitioner.id);
                } else {
                    return $uibModal.open({
                        templateUrl: 'app/entities/practitioner/practitioner-modal.html',
                        controller: 'PractitionerModalController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            practitioner: function () {
                                return practitioner;
                            }
                        }
                    });
                }
            });
        }

        function openPatient(patient) {
            $state.go(vm.isPharmacist ? 'patient-detail.pharmaceutics' : 'patient-detail.follow-up', {id: patient.id});
        }

        function previewSideEffectReport(id) {
            $uibModal.open({
                templateUrl: 'app/activity/side-effect-report/side-effect-report-preview.html',
                controller: 'SideEffectReportPreviewController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return SideEffectReport.get({id: id}).$promise;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('sideEffectReport');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function () {

            }, function () {

            });
        }

        function openMeasureChart(type) {
            $uibModal.open({
                templateUrl: 'app/activity/measure/measure-chart.html',
                controller: 'MeasureChartController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    type: function () {
                        return type;
                    },
                    patientId: function () {
                        return vm.visit.patientId;
                    },
                    translatePartialLoader: ['$translate', function ($translate) {
                        return $translate.refresh();
                    }]
                }
            }).result.then(function () {
            }, function () {
            });
        }

        function exportPdf() {
            vm.isExportingPdf = true;
            Visit.exportPdf({
                id: vm.visit.id
            }).$promise
                .then(DownloadFile.downloadFile)
                .finally(function () {
                    vm.isExportingPdf = !vm.isExportingPdf;
                });
        }

        function loadReminderPractitioners() {
            vm.reminderPractitioners = {};
            if (vm.visit.reminders !== null && vm.visit.reminders.length) {
                const practitionerIds = vm.visit.reminders
                    .filter(reminder => reminder.practitionerId !== null)
                    .map(reminder => reminder.practitionerId);
                Practitioner.query({'id.in': practitionerIds})
                    .$promise
                    .then(practitioners =>
                        practitioners.forEach(practitioner =>
                            vm.reminderPractitioners[practitioner.id] = practitioner));
            }
        }

        function editName() {
            vm.editingName = true;
        }

        function saveName() {
            vm.savingName = true;
            Visit.rename({id: vm.visit.id}, {name: vm.visit.name}).$promise
                .then(() => {
                    vm.savedName = vm.visit.name;
                })
                .catch(() => {
                    vm.visit.name = vm.savedName;
                })
                .finally(() => {
                    document.activeElement.blur();
                    vm.editingName = false;
                    vm.savingName = false;
                });
        }

        function restoreName() {
            vm.editingName = false;
            vm.visit.name = vm.savedName;
            document.activeElement.blur();
        }

        function onNameKeydown($event) {
            if ($event.which === 13) { // enter
                saveName();
            } else if ($event.which === 27) { // escape
                restoreName();
            }
        }

        function saveComment() {
            vm.savingComment = true;
            Visit.comment({id: vm.visit.id}, {comment: vm.visit.comment}).$promise
                .then(() => {
                    vm.savedComment = vm.visit.comment;
                })
                .catch(() => {
                    restoreComment();
                })
                .finally(() => {
                    vm.savingComment = false;
                    vm.commentForm.$setPristine();
                });
        }

        function restoreComment() {
            vm.visit.comment = vm.savedComment;
            vm.commentForm.$setPristine();
        }
    }
})();
