(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('HealthFacilityDetailController', HealthFacilityDetailController);

    HealthFacilityDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$uibModal', '$filter', '$window', 'entity', 'HealthFacility', 'Practitioner', 'Patient', 'Principal', 'HealthEntityContract', 'Disease'];

    function HealthFacilityDetailController($scope, $rootScope, $state, $stateParams, $uibModal, $filter, $window, entity, HealthFacility, Practitioner, Patient, Principal, HealthEntityContract, Disease) {
        var vm = this;

        vm.healthFacility = entity;
        vm.patients = null;

        vm.addPractitioner = addPractitioner;
        vm.addDiseaseDivision = addDiseaseDivision;
        vm.editDiseaseDivision = editDiseaseDivision;
        vm.removeDiseaseDivision = removeDiseaseDivision;

        $window.document.title = entity.name;

        loadPractitioners();
        loadDiseases();

        Principal.hasAuthority('ROLE_FINANCE').then(function (hasAuthority) {
            vm.hasFinanceRole = hasAuthority;
        });

        Principal.hasAuthority('ROLE_ADMIN').then(function (hasAuthority) {
            if (hasAuthority) {
                loadContracts();
            }
        });

        Principal.hasAuthority('ROLE_PRACTITIONER').then(function (hasAuthority) {
            if (hasAuthority) {
                loadPatients();
            }
        });

        var unsubscribe1 = $rootScope.$on('continuumplatformApp:healthFacilityUpdate', function (event, result) {
            vm.healthFacility = result;
        });
        var unsubscribe2 = $rootScope.$on('continuumplatformApp:practitionerUpdate', loadPractitioners);
        var unsubscribe3 = $rootScope.$on('continuumplatformApp:billUpdate', () => {
            loadFacility();
        });
        $scope.$on('$destroy', function () {
            unsubscribe1();
            unsubscribe2();
            unsubscribe3();
        });

        function loadFacility() {
            HealthFacility.get({
                id: vm.healthFacility.id
            }, function (result) {
                vm.healthFacility = result;
            });
        }

        function loadPractitioners() {
            Practitioner.query({
                "healthFacilityId.equals": vm.healthFacility.id,
                sort: "lastName,asc",
                size: 1000 // TODO pagination?
            }, function (practitioners) {
                vm.practitioners = practitioners;
            });
        }

        function loadDiseases() {
            Disease.query({
                size: 999 // TODO hard-coded size
            }).$promise.then(diseases => {
                // group by id and reduce to one disease per id
                vm.diseases = diseases.reduce((acc, disease) => {
                    acc[disease.id] = disease;
                    return acc;
                }, {});
            });
        }

        function loadPatients() {
            if (!['NURSING', 'PHARMACY'].includes(vm.healthFacility.type)) {
                return;
            }

            Principal.hasAuthority('ROLE_PRACTITIONER').then(function (hasAuthority) {
                if (hasAuthority) {
                    Patient.query({
                        hasCareTeamMemberId: vm.healthFacility.id,
                        "sort": "lastName,asc",
                        "size": 1000
                    }, patients => vm.patients = patients);
                }
            });
        }

        function addPractitioner() {
            $uibModal.open({
                templateUrl: 'app/entities/practitioner/practitioner-dialog.html',
                controller: 'PractitionerDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: {
                        healthFacilityId: vm.healthFacility.id,
                        job: null,
                        firstName: null,
                        lastName: null,
                        speciality: null,
                        category: null,
                        rpps: null,
                        adeli: null,
                        legalStatus: null,
                        legalSubStatus: null,
                        legalRegistrationNumber: null,
                        phone: null,
                        mobilePhone: null,
                        email: null,
                        address: null,
                        postalCode: null,
                        city: null,
                        country: 'FR',
                        latitude: null,
                        longitude: null,
                        notes: null,
                        userAuthType: null,
                        emailShown: false,
                        mobilePhoneShown: false,
                        validated: true,
                        notify: true
                    },
                    options: {
                        mode: 'addHealthFacility'
                    }
                }
            }).result.then(function () {
            }, function () {
            });
        }

        function addDiseaseDivision() {
            $uibModal.open({
                templateUrl: 'app/entities/health-facility/health-facility-division-dialog.html',
                controller: 'HealthFacilityDivisionDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    division: {
                        name: null,
                        diseaseIds: []
                    }
                }
            }).result.then(created => {
                HealthFacility.addDivision({id: vm.healthFacility.id}, created, () => {
                    loadFacility();
                });
            }, () => {
                // nothing to do
            });
        }

        function editDiseaseDivision(division) {
            $uibModal.open({
                templateUrl: 'app/entities/health-facility/health-facility-division-dialog.html',
                controller: 'HealthFacilityDivisionDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    division: angular.copy(division)
                }
            }).result.then(updated => {
                HealthFacility.updateDivision({id: vm.healthFacility.id, divisionName: division.name}, updated, () => {
                    loadFacility();
                });
            }, () => {
                // nothing to do
            });
        }

        function removeDiseaseDivision(division) {
            HealthFacility.deleteDivision({id: vm.healthFacility.id, divisionName: division.name}, () => {
                loadFacility();
            });
        }

        function loadContracts() {
            HealthEntityContract.query({
                "executorId.equals": vm.healthFacility.id,
                "sort": "signed"
            }, function (contracts) {
                vm.contracts = contracts;
            });
        }

    }
})();
