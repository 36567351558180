(function () {
    'use strict';

    const dashboard = {
        bindings: {
        },
        controller: DashboardController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/dashboard.html'
    };

    angular
        .module('continuumplatformApp')
        .component('dashboard', dashboard);

    DashboardController.$inject = ['$scope', 'Principal', 'Account', 'Patient', 'Practitioner', 'HealthFacility'];

    function DashboardController($scope, Principal, Account, Patient, Practitioner, HealthFacility) {

        const vm = this;

        // Initializes properties
        vm.initFinish = false;
        vm.today = new Date();

        /**
         * Contains reusable components for the virtual machine.
         *
         * @type {Array} An array containing objects representing the dashboard components.
         * Each object contains the following properties:
         * - component: The name of the dashboard component.
         * - width: The width of the component.
         * - mdWidth: The width of the component for medium-sized screens.
         * - lgWidth : The width of the component for large-sized screens.
         * - row: The row number of the component in the grid
         */
        vm.components = null;
        vm.filters = {
            diseaseIds: undefined,
            facilityDoctorId: undefined,
            coordinationPractitionerId: undefined,
            therapyType: null
        };

        vm.onChangeCoordinationPractitioner = onChangeCoordinationPractitioner;

        /**
         * Initialize the component.
         */
        vm.$onInit = () => {
            getAccount()
                .then(account => {
                    if(account.practitioner) {
                        vm.practitioner = account.practitioner;
                        return account;
                    }
                    return Promise.reject('No practitioner found');
                })
                .then(loadSettings)
                .then(() => loadDiseaseDivisionsAndTrials(vm.practitioner.healthFacilityId))
                .then(() => {
                    if (vm.isHospitalUser && vm.isDoctor) {
                        return loadFacilityDoctor();
                    } else if (vm.isCoordination) {
                        return loadCoordinationPractitioners(vm.practitioner.healthFacilityId);
                    }
                }).then(() => {
                    vm.components = getDefaultComponentSetting();
                });

        };

        vm.$onDestroy = () => {
            if (vm.unwatchDiseaseDivisionName) {
                vm.unwatchDiseaseDivisionName();
            }
            if (vm.unwatchTrial) {
                vm.unwatchTrial();
            }
        };

        /**
         * Retrieves the current user's account information.
         *
         * @returns {Promise} A Promise object that resolves to the account information.
         */
        function getAccount() {
            return Principal.identity().then(function (account) {

                if (account) {
                    vm.account = account;
                    vm.isPatient = account.authorities && account.authorities.includes('ROLE_PATIENT');
                    vm.isAdmin = account.authorities && account.authorities.includes('ROLE_ADMIN');

                    if (account.practitioner) {
                        vm.isCoordination = account.practitioner.healthFacilityType === 'COORDINATION';
                        vm.isHospitalUser = account.practitioner.healthFacilityType === 'HOSPITAL';

                        vm.isDoctor = account.practitioner.job === 'J10';
                        vm.isPharmacist = account.practitioner.job === 'J21';
                        vm.isNurse = account.practitioner.job === 'J60';
                        vm.isOtherPractitioner = account.practitioner.job === 'J99';
                    }
                }

                return vm.account;
            });
        }

        function loadSettings(account) {
            if (account.settings) {
                if (account.settings.diseaseDivisionName) {
                    // load disease division name from account settings
                    vm.diseaseDivisionName = account.settings.diseaseDivisionName;
                }
                if (account.settings.trial) {
                    // load trial from account settings
                    vm.filters.trial = account.settings.trial;
                } else {
                    vm.filters.trial = undefined;
                }
            }
        }

        /**
         * Retrieves the default component settings based on the user type.
         *
         * @return {{component: string, width: number, mdWidth: number, row?: number}[]}
         */
        function getDefaultComponentSetting() {
            let components = [];

            if (vm.isCoordination && vm.isAdmin) {
                components = defineDashboardComponentForContinuumCoordination();

            } else if (vm.isCoordination) {
                components = defineDashboardComponentForCoordination();

            } else if (vm.isDoctor && vm.isHospitalUser) {
                components = defineDashboardComponentForHospitalDoctor();

            } else if (vm.isNurse && vm.isHospitalUser) {
                components = defineDashboardComponentForHospitalNurse();

            } else if (vm.isPharmacist && vm.isHospitalUser) {
                components = defineDashboardComponentForHospitalPharmacist();
            }

            return components;
        }

        /**
         * Defines the dashboard components for the Continuum Coordination.
         * @return {{component: string, width: number, mdWidth: number}[]}
         */
        function defineDashboardComponentForContinuumCoordination() {
            return [
                {component: 'new-message-block'},
                {component: 'visits-todo-block'},
                {component: 'follow-up-to-start-block'},
                {component: 'suspended-follow-up-block'},
                {component: 'unbilled-home-visits-block'},
                {component: 'contract-in-progress-block'},
            ];
        }

        /**
         * Defines the dashboard components for coordination facility practitioners.
         *
         * @return {{component: string, width: number, mdWidth: number}[]}
         */
        function defineDashboardComponentForCoordination() {
            return [
                {component: 'new-message-block'},
                {component: 'visits-todo-block'},
                {component: 'follow-up-to-start-block'},
                {component: 'suspended-follow-up-block'},
            ];
        }

        /**
         * Defines the dashboard components for a hospital doctor.
         *
         * @return {{component: string, width: number, mdWidth: number, row?: number}[]}
         */
        function defineDashboardComponentForHospitalDoctor() {
            return [
                {component: 'alert-block'},
                {component: 'new-message-block'},
                {component: 'new-biologies-results-block'},
                {component: 'new-pharm-analysis-block'},
            ];
        }

        /**
         * Defines the dashboard component settings for a hospital nurse.
         *
         * @return {{component: string, width: number, mdWidth: number}[]}
         */
        function defineDashboardComponentForHospitalNurse() {
            return [
                {component: 'alert-block'},
                {component: 'new-message-block'},
                {component: 'new-biologies-results-block'},
                {component: 'visits-todo-block'},
                {component: 'hdj-block'},
                {component: 'follow-up-to-start-block'},
                {component: 'suspended-follow-up-block'},
            ];
        }

        /**
         * Defines the dashboard component settings for a hospital pharmacist.
         *
         * @return {{component: string, width: number, mdWidth: number}[]}
         */
        function defineDashboardComponentForHospitalPharmacist() {
            return [
                {component: 'alert-block'},
                {component: 'new-message-block'},
                {component: 'new-pharm-analysis-block'},
                {component: 'visits-todo-block'},
            ];
        }

        function loadFacilityDoctor() {
            vm.filters.facilityDoctorId = vm.practitioner.id;
        }

        function loadCoordinationPractitioners(healthFacilityId) {
            const filter = 'coordinationPractitionerId';
            const stored = sessionStorage.getItem('dashboard.filters.' + filter);
            if(stored) {
                vm.filters[filter] = parseInt(stored);
            }
            return loadPractitioners(Practitioner.query,  filter, healthFacilityId);
        }

        function loadPractitioners(fn, filter, healthFacilityId) {

            if (angular.isUndefined(vm.filters[filter])) {
                vm.selectedPractitioner = vm.practitioner;
                vm.filters[filter] = vm.practitioner.id;
            }
            vm.forcePractitioner = vm.practitioner;

            return fn({
                "healthFacilityId.equals": healthFacilityId,
            }).$promise.then(practitioners => {
                vm.practitioners = practitioners;
                if (vm.forcePractitioner && (!practitioners.length || !practitioners.find(practitioner => practitioner.id === vm.forcePractitioner.id))) {
                    vm.practitioners.push(vm.forcePractitioner);
                }
                if (vm.filters[filter]) {
                    vm.selectedPractitioner = practitioners.find(practitioner => practitioner.id === vm.filters[filter]);
                }
                return vm.practitioners;
            });
        }

        function onChangeCoordinationPractitioner(practitioner) {
            onChangePractitioner('coordinationPractitionerId', practitioner);
        }

        function onChangePractitioner(filter, practitioner) {
            if (practitioner) {
                vm.filters[filter] = practitioner.id;
                vm.selectedPractitioner = practitioner;
            } else {
                vm.filters[filter] = null;
                vm.selectedPractitioner = null;
            }
            sessionStorage.setItem('dashboard.filters.' + filter, vm.filters[filter]);
        }

        function loadDiseaseDivisionsAndTrials(healthFacilityId) {
            if (!healthFacilityId) {
                vm.enableDiseaseDivisionFilter = false;
                vm.diseaseDivisionName = undefined;
                vm.filters.diseaseIds = undefined;

                vm.enableTrialFilter = false;
                vm.filters.trial = undefined;
                return Promise.resolve();
            }
            return HealthFacility.get({
                view: 'summary',
                id: healthFacilityId
            }, facility => {

                if (vm.isCoordination || (vm.isDoctor && vm.isHospitalUser)) {
                    vm.enableDiseaseDivisionFilter = false;
                    vm.diseaseDivisionName = undefined;
                    vm.filters.diseaseIds = undefined;
                } else {

                    facility.diseaseDivisions = facility.diseaseDivisions || [];

                    vm.enableDiseaseDivisionFilter = facility.diseaseDivisions.length > 0;

                    // group by division name reduce to 1 division by name
                    vm.divisions = facility.diseaseDivisions.reduce((divisions, division) => {
                        divisions[division.name] = division.diseaseIds;
                        return divisions;
                    }, {});

                    // restore previously selected division with updated data
                    if (vm.diseaseDivisionName) {
                        if (vm.divisions[vm.diseaseDivisionName]) {
                            vm.filters.diseaseIds = vm.divisions[vm.diseaseDivisionName];
                        } else {
                            vm.diseaseDivisionName = undefined;
                            vm.filters.diseaseIds = undefined;
                        }
                    }

                    vm.unwatchDiseaseDivisionName = $scope.$watch('vm.diseaseDivisionName', onChangeDivision);
                }

                // trials
                vm.enableTrialFilter = facility.trials.length > 0;
                vm.trials = facility.trials;

                vm.unwatchTrial = $scope.$watch('vm.filters.trial', onChangeTrial);

            }).$promise;
        }

        function onChangeDivision(newVal, oldVal) {
            if(newVal === oldVal) {
                return;
            } else if (newVal) {
                vm.filters.diseaseIds = vm.divisions[newVal];
            } else {
                vm.filters.diseaseIds = undefined;
            }
            storeDiseaseDivisionName(newVal);
        }

        function storeDiseaseDivisionName(value) {
            if(value)
                Principal.updateSetting('diseaseDivisionName', value);
            else
                Principal.deleteSetting('diseaseDivisionName');
        }

        function onChangeTrial(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            storeTrial(newVal);
        }

        function storeTrial(value) {
            if (value)
                Principal.updateSetting('trial', value);
            else
                Principal.deleteSetting('trial');
        }

    }

})();
