(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PendingVisitsController', PendingVisitsController);

    PendingVisitsController.$inject = ['$filter', '$scope', '$state', '$log', '$uibModal', 'filters', 'Principal', 'Visit', 'DownloadFile', 'HealthFacility', 'Practitioner', 'HDJ'];

    function PendingVisitsController($filter, $scope, $state, $log, $uibModal, filters, Principal, Visit, DownloadFile, HealthFacility, Practitioner, HDJ) {

        const vm = this;

        vm.filters = filters;
        vm.loading = true;

        vm.$onInit = () => {

            Principal.identity()
                .then((account) => {

                    if (account.settings && account.settings.diseaseDivisionName) {
                        // load disease division name from account settings
                        vm.diseaseDivisionName = account.settings.diseaseDivisionName;
                    }

                    let promise;
                    if (account.practitioner && account.practitioner.healthFacilityId) {
                        promise = loadDiseaseDivisions(account.practitioner.healthFacilityId);
                    } else {
                        promise = Promise.resolve();
                    }
                    promise.then(() => {
                        loadVisits();
                    });

                });

            vm.unwatchTherapyType = $scope.$watch('vm.filters.therapyType', (newVal, oldVal) => {
                if (oldVal !== newVal) {
                    loadVisits();
                }
            });

        };

        vm.$onDestroy = () => {
            if (vm.unwatchTherapyType) {
                vm.unwatchTherapyType();
            }
            if (vm.unwatchDiseaseDivisionName) {
                vm.unwatchDiseaseDivisionName();
            }
        };

        vm.applyFromDate = loadVisits;

        function loadDiseaseDivisions(healthFacilityId) {
            if (!healthFacilityId) {
                vm.enableDiseaseDivisionFilter = false;
                vm.diseaseDivisionName = undefined;
                vm.filters.diseaseIds = undefined;
                return Promise.resolve();
            }
            return HealthFacility.get({
                view: 'summary',
                id: healthFacilityId
            }, facility => {

                facility.diseaseDivisions = facility.diseaseDivisions || [];

                vm.enableDiseaseDivisionFilter = facility.diseaseDivisions.length > 0;

                // group by division name reduce to 1 division by name
                vm.divisions = facility.diseaseDivisions.reduce((divisions, division) => {
                    divisions[division.name] = division.diseaseIds;
                    return divisions;
                }, {});

                // restore previously selected division with updated data
                if (vm.diseaseDivisionName) {
                    if (vm.divisions[vm.diseaseDivisionName]) {
                        vm.filters.diseaseId = undefined;
                        vm.filters.diseaseIds = vm.divisions[vm.diseaseDivisionName];
                    } else {
                        vm.diseaseDivisionName = undefined;
                        vm.filters.diseaseIds = undefined;
                    }
                }

                vm.unwatchDiseaseDivisionName = $scope.$watch('vm.diseaseDivisionName', onChangeDivision);
            }).$promise;
        }

        function onChangeDivision(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            } else if (newVal) {
                vm.filters.diseaseId = undefined;
                vm.filters.diseaseIds = vm.divisions[newVal];
            } else {
                vm.filters.diseaseIds = undefined;
            }
            storeDiseaseDivisionName(newVal);
            loadVisits();
        }

        function storeDiseaseDivisionName(value) {
            if (value)
                Principal.updateSetting('diseaseDivisionName', value);
            else
                Principal.deleteSetting('diseaseDivisionName');
        }

        function loadVisits() {
            vm.loading = true;
            vm.visits = null;
            HDJ.query({
                diseaseIds: getDiseaseIdIn(),
                fromDate: moment(vm.filters.fromDate).format('YYYY-MM-DD'),
                types: types()
            }).then((response) => {
                onSuccess(response.data, response.headers);
            }).finally(() => {
                vm.loading = false;
            });
        }

        function types() {
            if (vm.filters.therapyType === 'hdj') {
                return ['HOSPITAL']
            }
            if (vm.filters.therapyType === 'hah') {
                return ['HAH'];
            }
            return undefined;
        }

        function onSuccess(data, headers) {
            vm.visits = data;

            vm.dates = $filter('groupBy')(vm.visits
                .map(visit => {
                    visit.plannedDateString = moment(visit.plannedDate).format('YYYY-MM-DD');
                    return visit;
                }), 'plannedDateString');
        }

        function getDiseaseIdIn() {
            if (vm.filters.diseaseIds && vm.filters.diseaseIds.length) {
                return vm.filters.diseaseIds;
            }
            return undefined;
        }

    }
})();
