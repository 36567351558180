(function () {
    'use strict';

    const painPanel = {
        bindings: {
            patientId: '<',
            patientTherapy: '<'
        },
        controller: PainPanelController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/measure/pain-panel.html'
    };

    angular
        .module('continuumplatformApp')
        .component('painPanel', painPanel);

    PainPanelController.$inject = ['$rootScope', '$scope', '$uibModal', 'Principal', 'Measure', 'PatientTherapy'];

    function PainPanelController($rootScope, $scope, $uibModal, Principal, Measure, PatientTherapy) {

        const vm = this;

        vm.$onInit = () => {
            vm.enabled = vm.patientTherapy ? vm.patientTherapy.measureTypes.includes('PAIN') : null;
            loadAccount();
            loadMeasures();
            vm.measureUpdateUnsubscribe = $rootScope.$on('continuumplatformApp:measureUpdate',  loadMeasures);
        };

        vm.$onDestroy = () => {
            vm.measureUpdateUnsubscribe();
        };

        function loadAccount() {
            Principal.identity().then(account => {
                vm.account = account;
                vm.isPractitioner = account && account.authorities.includes('ROLE_PRACTITIONER');
                vm.isPatient = account.authorities && account.authorities.includes('ROLE_PATIENT');
            });
        }

        function loadMeasures() {
            vm.loadingData = true;
            Measure.query({
                view: 'base',
                "patientId.equals": vm.patientId,
                "type.equals": 'PAIN',
                "sort": ["date,desc"],
                size: 1
            }, function (measures, headers) {
                vm.lastPainMeasure = measures[0];
                vm.loadingData = false;
                vm.totalItems = headers('X-Total-Count');
            });
        }

        vm.addMeasure = () => {
            $uibModal.open({
                templateUrl: 'app/activity/measure/measure-dialog.html',
                controller: 'MeasureDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: () => {
                        return {
                            patientId: vm.patientId,
                            // TODO vm.patientTherapy.id
                            authorId: null,
                            date: null,
                            value: null,
                            type: 'PAIN'
                        };
                    }
                }
            }).result.then(() => {
                // nothing
            }, () => {
                // nothing
            });
        };

        vm.toggle = () => {
            if (!vm.patientTherapy || vm.isToggling) {
                return;
            }
            vm.isToggling = true;
            let fn;
            let newValue = !vm.enabled;
            if (newValue) {
                fn = PatientTherapy.addMeasureType;
            } else {
                fn = PatientTherapy.removeMeasureType;
            }
            fn({id: vm.patientTherapy.id, type: 'PAIN'}, {}).$promise
                .then(() => {
                    vm.enabled = newValue;
                })
                .finally(() => {
                    vm.isToggling = false;
                });
        };
    }

})();
