(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyDialogController', PatientTherapyDialogController);

    PatientTherapyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$filter', '$translate', 'entity', 'PatientTherapy', 'Patient', 'HealthFacility', 'Practitioner', 'Therapy', 'Principal', 'ContractLine', 'PatientTreatment', 'CareTeam', 'Program'];

    function PatientTherapyDialogController($timeout, $scope, $stateParams, $uibModalInstance, $filter, $translate, entity, PatientTherapy, Patient, HealthFacility, Practitioner, Therapy, Principal, ContractLine, PatientTreatment, CareTeam, Program) {
        var vm = this;

        vm.patientTherapy = entity;
        vm.form = {
            day1Date: entity.day1Date,
            stopDate: entity.stopDate,
            notes: entity.notes,
            internalNote: null,
            medicationsIds: entity.medications.map(m => m.id),
            assigneeId: entity.assignee && entity.assignee.id,
            patientStatus: entity.patientStatus
        };
        vm.medications = entity.medications;

        if(vm.patientTherapy.status === 'S11_VALIDATED') {
            vm.form.therapyId = entity.therapyId;
        }

        vm.clear = clear;
        vm.save = save;

        loadTherapyContractLines();

        loadCoordinators();

        Principal.identity().then(function (account) {
            vm.isAdmin = account.authorities.includes('ROLE_ADMIN');
        });

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        vm.$onInit = () => {
            vm.unWatchTherapyId = $scope.$watch("vm.form.therapyId", onTherapyIdChange);
        };

        vm.$onDestroy = () => {
            vm.unWatchTherapyId();
        };

        function onTherapyIdChange(newVal, oldVal) {
            if (newVal) {
                loadTherapy();
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            PatientTherapy.update({id: vm.patientTherapy.id}, vm.form, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('continuumplatformApp:patientTherapyUpdate', result);
            $scope.$emit('continuumplatformApp:internalNotesUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function loadTherapyContractLines() {
            PatientTherapy.getTherapyContractLines({
                id: vm.patientTherapy.id,
                date: moment(vm.patientTherapy.startDate || new Date()).format('YYYY-MM-DD')
            }, function (therapies) {
                vm.therapyContractLines = therapies
                    .map(prefixTherapy)
                    .sort((tcl1, tcl2) => tcl1.therapyName.localeCompare(tcl2.therapyName));

                // add existing
                if (vm.patientTherapy.therapyId
                    && !vm.therapyContractLines
                        .filter(function (tcl) {
                            return tcl.therapyId === vm.patientTherapy.therapyId;
                        }).length) {

                    if (vm.patientTherapy.contractLineId) {
                        ContractLine.get({id: vm.patientTherapy.contractLineId}, function (cl) {
                            vm.therapyContractLines.push({
                                therapyId: vm.patientTherapy.therapyId,
                                therapyName: vm.patientTherapy.therapyName,
                                lineId: cl.id,
                                customerName: cl.customerName,
                                healthFacilityName: cl.healthFacilityName,
                                contractComment: cl.contractComment,
                                lineComment: cl.lineComment,
                                contractQuota: cl.contractQuota,
                                contractConsumption: cl.contractConsumption,
                            });
                        });
                    } else {
                        vm.therapyContractLines.push({
                            therapyId: vm.patientTherapy.therapyId,
                            therapyName: vm.patientTherapy.therapyName,
                        });
                    }

                }
            });
        }

        function prefixTherapy(tcl) {
            if (tcl.therapyHah)
                tcl.prefix = 'HAD / ';
            else if (tcl.therapyHdj)
                tcl.prefix = 'HDJ / ';
            tcl.prefix = null;
            return tcl;
        }

        function loadTherapy() {
            Therapy.get({
                id: vm.form.therapyId
            }, therapy => {
                vm.therapy = therapy;
            });
        }

        function loadCoordinators() {
            vm.coordinators = [];

            Program.get({id: vm.patientTherapy.programId}, program => {
                CareTeam.getMembers(vm.patientTherapy.patientId).then(getMembersResponse => {
                    let facilityId;
                    if (program.coordinationFacilityId) {
                        const coordinationFacilityInTeam = getMembersResponse.data
                            .filter(m => m.entity.id === program.coordinationFacilityId)
                            .length > 0;
                        if(coordinationFacilityInTeam) {
                            facilityId = program.coordinationFacilityId;
                        }
                    } else {
                        const patientFacilityInTeam = getMembersResponse.data
                            .filter(m => m.entity.id === vm.patientTherapy.patient.healthFacilityId)
                            .length > 0;
                        if (patientFacilityInTeam) {
                            facilityId = vm.patientTherapy.patient.healthFacilityId;
                        }
                    }
                    if (facilityId) {
                        Practitioner.query({
                            "job.in": ['J21', 'J38', 'J60'],
                            "healthFacilityId.equals": facilityId
                        }, practitioners => {
                            vm.coordinators = practitioners.filter(currentAssigneeOrActiveUser);
                            addCurrentAssigneeIfNotInList();
                        });
                    } else {
                        vm.coordinators = getMembersResponse.data
                            .filter(m => ['NURSE_COORD', 'FACILITY_PHARMACIST'].includes(m.role))
                            .filter(currentAssigneeOrActiveUser)
                            .map(m => m.entity);
                        addCurrentAssigneeIfNotInList();
                    }
                });
            });
        }

        function currentAssigneeOrActiveUser(practitioner) {
            return (vm.patientTherapy.assignee && vm.patientTherapy.assignee.id === practitioner.id)
                || !['NO_USER', 'DISABLED'].includes(practitioner.userStatus);
        }

        function addCurrentAssigneeIfNotInList() {
            if (vm.patientTherapy.assignee &&
                vm.patientTherapy.assignee.id
                && !vm.coordinators.map(c => c.id).includes(vm.patientTherapy.assignee.id)) {
                Practitioner.get({id: vm.patientTherapy.assignee.id}, practitioner => {
                    vm.coordinators.push(practitioner);
                });
            }
        }

        // TODO filter compatible treatments
        vm.filterMedications = function (patientTreatment) {
            return true;
        };
    }
})();
