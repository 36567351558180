(function () {
    'use strict';

    const measuresPanel = {
        bindings: {
            patientId: '<',
            patientTherapyId: '<',
            measureTypes: '<',
            expand: '<'
        },
        controller: MeasuresPanelController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/measure/measures-panel.html'
    };

    angular
        .module('continuumplatformApp')
        .component('measuresPanel', measuresPanel);

    MeasuresPanelController.$inject = ['$rootScope', '$scope', '$uibModal', '$filter', 'Measure', 'MeasureType'];

    function MeasuresPanelController($rootScope, $scope, $uibModal, $filter, Measure, MeasureType) {

        const vm = this;

        vm.rules = MeasureType.getRules();

        const unsubscribeMeasureUpdate = $rootScope.$on('continuumplatformApp:measureUpdate', () => {
            loadMeasures();
        });
        const unsubscribeSetMeasureTypes = $rootScope.$on('continuumplatformApp:setMeasureTypes', (event, measureTypes) => {
            vm.measureTypes = measureTypes;
        });

        vm.$onInit = () => {
            loadMeasures();
        };

        vm.$onDestroy = () => {
            unsubscribeMeasureUpdate();
            unsubscribeSetMeasureTypes();
        };

        vm.setMeasureTypes = () => {
            if (!vm.patientTherapyId) {
                return;
            }
            $uibModal.open({
                templateUrl: 'app/activity/measure/set-measure-types-modal.html',
                controller: 'SetMeasureTypesModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    patientTherapy: ['PatientTherapy', (PatientTherapy) => PatientTherapy.get({id: vm.patientTherapyId}).$promise]
                }
            }).result.then(() => {
                vm.measures = generatePanel();
            }, () => {
                vm.measures = generatePanel();
            });
        };

        vm.addMeasure = () => {
            $uibModal.open({
                templateUrl: 'app/activity/measure/measure-dialog.html',
                controller: 'MeasureDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: () => {
                        return {
                            patientId: vm.patientId,
                            authorId: null,
                            date: null,
                            value: null,
                            type: null
                        };
                    }
                }
            }).result.then(() => {
                // nothing
            }, () => {
                // nothing
            });
        };

        vm.chart = (type) => {
            $uibModal.open({
                templateUrl: 'app/activity/measure/measure-chart.html',
                controller: 'MeasureChartController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    type: () => type,
                    patientId: () => vm.patientId
                }
            }).result.then(() => {
                // nothing
            }, () => {
                // nothing
            });
        };

        function loadMeasures() {
            Measure.query({
                view: 'base',
                "patientId.equals": vm.patientId,
                'type.notEquals': 'PAIN',
                "sort": ["type,asc", "date,asc"],
                size: 99999  // TODO all
            }, (measures) => {
                vm.rawMeasures = measures;
                vm.measures = generatePanel();
            });
        }

        function generatePanel() {

            let measureTypesSet = null;
            // TODO convert virtual/calculated measureTypes
            if(vm.measureTypes) {
                measureTypesSet = new Set(vm.measureTypes);
                if(measureTypesSet.has('BLOOD_PRESSURE_SYSTOLIC') || measureTypesSet.has('BLOOD_PRESSURE_DIASTOLIC')) {
                    measureTypesSet.delete('BLOOD_PRESSURE_SYSTOLIC');
                    measureTypesSet.delete('BLOOD_PRESSURE_DIASTOLIC');
                    measureTypesSet.add('BLOOD_PRESSURE');
                }
                // TODO BMI?
                if(measureTypesSet.has('BMI')) {
                    measureTypesSet.add('WEIGHT');
                    measureTypesSet.add('SIZE');
                }
            }

            const result = [];
            const measuresByType = $filter('groupBy')(vm.rawMeasures.map(function (measure) {
                if (measure.type === 'BLOOD_PRESSURE_SYSTOLIC' || measure.type === 'BLOOD_PRESSURE_DIASTOLIC') {
                    measure.subType = measure.type;
                    measure.type = 'BLOOD_PRESSURE';
                }
                return measure;
            }), 'type');
            const types = [];

            for (let type in measuresByType) {

                if(measureTypesSet && !measureTypesSet.has(type)) {
                    continue;
                }

                const lastMeasure = {};

                if (type === 'BLOOD_PRESSURE') {

                    measuresByType[type].forEach((v) => {
                        if (v.subType === 'BLOOD_PRESSURE_SYSTOLIC') {
                            lastMeasure.value1 = v.value;
                            lastMeasure.score1 = v.score;
                        } else if (v.subType === 'BLOOD_PRESSURE_DIASTOLIC') {
                            lastMeasure.value2 = v.value;
                            lastMeasure.score2 = v.score;
                        }

                        lastMeasure.date = v.date;
                    });

                    lastMeasure.score = Math.max(lastMeasure.score1, lastMeasure.score2);

                } else {

                    for (let i in measuresByType[type]) {
                        lastMeasure.value1 = measuresByType[type][i].value;
                        lastMeasure.score = measuresByType[type][i].score;
                        lastMeasure.date = measuresByType[type][i].date;
                    }

                }

                types.push(type);
                result.push({
                    type: type,
                    lastMeasure: lastMeasure
                });

            }

            if(measureTypesSet) {
                [...measureTypesSet]
                    .filter(type => type !== 'PAIN')
                    .filter(type => !types.includes(type))
                    .forEach(type => {
                        result.push({
                            type: type,
                            lastMeasure: null
                        });
                    });
            }

            // trie en fonction du type pour avoir l'affichage dans l'ordre souhaité
            result.sort((m1, m2) => getMeasureTypeSortScore(m1.type) - getMeasureTypeSortScore(m2.type));

            return result;
        }

        function getMeasureTypeSortScore(type) {
            switch (type) {
            case 'BLOOD_PRESSURE':
                return 0;
            case 'HEART_RATE':
                return 1;
            case 'SATO2':
                return 2;
            case 'RESPIRATORY_RATE':
                return 3;
            case 'TEMPERATURE':
                return 4;
            case 'SIZE':
                return 5;
            case 'WEIGHT':
                return 6;
            case 'BMI':
                return 7;
            case 'GFR':
                return 8;
            case 'CLAIRANCE':
                return 9;
            }

        }
    }

})();
